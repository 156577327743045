import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Card, Button } from "react-bootstrap";
import Rain from "./../../../images/rainbow.png";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/auth/authSlice";
//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import { IMAGES, SVGICON } from "../../constant/theme";
import {
  useCreateAttendanceMutation,
  useUpdateAttendanceMutation,
  useGetAttendancesQuery,
} from "../../../features/hrm/attendance/attendanceApiSlice";
import { Dropdown } from "react-bootstrap";

import ProjectOverviewTab from "./elements/ProjectOverviewTab";
import ToDoList from "./elements/ToDoList";
import ActiveProjects from "./elements/ActiveProjects";
import RunningSwiper from "./RunningSwiper";
import UpcomingBlog from "./elements/UpcomingBlog";
import AllProjectBlog from "./elements/AllProjectBlog";
import { notifyError, notifySuccess } from "../../../helpers/Notify";
import { useGetAllInvoicesQuery } from "../../../features/financialMangement/invoice/invoiceApiSlice";
import { useGetTaskQuery } from "../../../features/taskManagement/taskManagementApiSlice";
import { useGetAllCallOutTaskQuery } from "../../../features/callOutTask/callOutTaskApiSlice";
import { useGetExpenseByYearQuery } from "../../../features/financialMangement/expense/expense/expenseApiSlice";
import { useGetAllAccountsQuery } from "../../../features/financialMangement/account/accountApiSlice";

const RevenueChart = loadable(() =>
  pMinDelay(import("./elements/RevenueChart"), 1000)
);
const ExpensesBarChart = loadable(() =>
  pMinDelay(import("./elements/ExpensesBarChart"), 1000)
);
const TotalDeposit = loadable(() =>
  pMinDelay(import("./elements/TotalDeposit"), 1000)
);
const MyProgressChart = loadable(() =>
  pMinDelay(import("./elements/MyProgressChart"), 1000)
);

const filterOptions = [
  { title: "year" },
  { title: "month" },
  { title: "week" },
  { title: "today" },
];

const Home = () => {
  const [postAttendance, { isLoading }] = useCreateAttendanceMutation();
  const [updateAttendance] = useUpdateAttendanceMutation();
  const { data: todayAttendence = [], refetch: refetchAttendance } =
    useGetAttendancesQuery();

  const user = useSelector(selectCurrentUser);
  const { data: accounts = [] } = useGetAllAccountsQuery();
  // const { data: invoices = [] } = useGetAllUnpaidInvoicesQuery();
  const { data: task = [], isSuccess: SuccessTask } = useGetTaskQuery();
  const { data: callOutTask = [], isSuccess: SuccessCallOutTask } =
    useGetAllCallOutTaskQuery();

  const { data: allExpenses, isSuccess: SuccessExpense } =
    useGetExpenseByYearQuery({ year: new Date().getFullYear() });

  const { data: Allinvoices = [], refetch: refetchAllInvoice } =
    useGetAllInvoicesQuery();

  const [totalAR, setTotalAR] = useState(0);
  const [taskData, setTaskData] = useState({
    totalTask: 0,
    pendingTask: 0,
    inProgressTask: 0,
    completeTask: 0,
  });
  const [revenueFilter, setRevenueFilter] = useState("year");
  const [expenseFilter, setExpenseFilter] = useState("year");
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based (Jan is 0, Dec is 11)
    const currentDate = today.getDate(); // Day of the month
    const currentDayOfWeek = today.getDay(); // Day of the week (0 for Sunday, 6 for Saturday)

    // Calculate the start of the current week (Sunday) and the last 6 days from today
    const startOfWeek = new Date(today);
    startOfWeek.setDate(currentDate - currentDayOfWeek); // Adjust to start of the week

    if (Allinvoices?.invoices?.length > 0) {
      // Filter invoices by year
      const currentYearInvoices = Allinvoices?.invoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);
        return invoiceDate.getFullYear() === currentYear;
      });

      // Filter invoices by month
      const currentMonthInvoices = currentYearInvoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);
        return invoiceDate.getMonth() === currentMonth;
      });

      // Filter invoices by current day
      const currentDateInvoices = currentMonthInvoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);
        return invoiceDate.getDate() === currentDate;
      });

      // Filter invoices by week (from start of week to current date)
      const currentWeekInvoices = currentYearInvoices?.filter((invoice) => {
        const invoiceDate = new Date(invoice?.invoiceDate);
        return invoiceDate >= startOfWeek && invoiceDate <= today;
      });

      // Set total revenue based on the selected filter
      let totalRevenue = 0;
      if (revenueFilter === "year" && currentYearInvoices?.length > 0) {
        totalRevenue = currentYearInvoices.reduce((sum, invoice) => {
          return sum + parseFloat(invoice?.totalAmount);
        }, 0);
      } else if (
        revenueFilter === "month" &&
        currentMonthInvoices?.length > 0
      ) {
        totalRevenue = currentMonthInvoices.reduce((sum, invoice) => {
          return sum + parseFloat(invoice?.totalAmount);
        }, 0);
      } else if (revenueFilter === "today" && currentDateInvoices?.length > 0) {
        totalRevenue = currentDateInvoices?.reduce((sum, invoice) => {
          return sum + parseFloat(invoice?.totalAmount);
        }, 0);
      } else if (revenueFilter === "week" && currentWeekInvoices?.length > 0) {
        totalRevenue = currentWeekInvoices.reduce((sum, invoice) => {
          return sum + parseFloat(invoice?.totalAmount);
        }, 0);
      } else {
        totalRevenue = 0;
      }

      setTotalRevenue(totalRevenue);
    } else {
      setTotalRevenue(0);
    }

    // Task calculations
    if (SuccessTask && SuccessCallOutTask) {
      const finalArray = task.concat(callOutTask);

      const pendingTask = finalArray?.filter(
        (task) => task?.status === "Pending"
      );
      const inProgressTask = finalArray?.filter(
        (task) => task?.status === "In Progress"
      );
      const completeTask = finalArray?.filter(
        (task) => task?.status === "Complete"
      );

      setTaskData({
        totalTask: finalArray?.length,
        pendingTask: pendingTask?.length,
        inProgressTask: inProgressTask?.length,
        completeTask: completeTask?.length,
      });
    }
  }, [task, callOutTask, revenueFilter, allExpenses, Allinvoices]);

  useEffect(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based (Jan is 0, Dec is 11)
    const currentDate = today.getDate(); // Day of the month
    const currentDayOfWeek = today.getDay(); // Day of the week (0 for Sunday, 6 for Saturday)

    // Calculate the start of the current week (Sunday) and the last 6 days from today
    const startOfWeek = new Date(today);
    startOfWeek.setDate(currentDate - currentDayOfWeek); // Adjust to start of the week

    if (allExpenses?.expenses?.length > 0) {
      // Filter invoices by year
      const currentYearExpense = allExpenses?.expenses?.filter((expense) => {
        const expenseDate = new Date(expense?.date);
        return expenseDate.getFullYear() === currentYear;
      });

      const currentMonthExpense = currentYearExpense?.filter((expense) => {
        const expenseDate = new Date(expense?.date);
        return expenseDate.getMonth() === currentMonth;
      });

      // Filter invoices by current day
      const currentDateExpense = currentMonthExpense?.filter((expense) => {
        const expenseDate = new Date(expense?.date);
        return expenseDate.getDate() === currentDate;
      });

      // Filter invoices by week (from start of week to current date)
      const currentWeekExpense = currentYearExpense?.filter((expense) => {
        const expenseDate = new Date(expense?.date);
        return expenseDate >= startOfWeek && expenseDate <= today;
      });

      let totalExpense = 0;
      if (expenseFilter === "year" && currentYearExpense?.length > 0) {
        totalExpense = currentYearExpense.reduce((sum, entry) => {
          return (
            sum +
            parseFloat(entry?.debits > 0 ? entry?.debits : 0) -
            parseFloat(entry?.credits > 0 ? entry?.credits : 0)
          );
        }, 0);
      } else if (expenseFilter === "month" && currentMonthExpense?.length > 0) {
        totalExpense = currentMonthExpense.reduce((sum, entry) => {
          return (
            sum +
            parseFloat(entry?.debits > 0 ? entry?.debits : 0) -
            parseFloat(entry?.credits > 0 ? entry?.credits : 0)
          );
        }, 0);
      } else if (expenseFilter === "today" && currentDateExpense?.length > 0) {
        totalExpense = currentDateExpense?.reduce((sum, entry) => {
          return (
            sum +
            parseFloat(entry?.debits > 0 ? entry?.debits : 0) -
            parseFloat(entry?.credits > 0 ? entry?.credits : 0)
          );
        }, 0);
      } else if (expenseFilter === "week" && currentWeekExpense?.length > 0) {
        totalExpense = currentWeekExpense.reduce((sum, entry) => {
          return (
            sum +
            parseFloat(entry?.debits > 0 ? entry?.debits : 0) -
            parseFloat(entry?.credits > 0 ? entry?.credits : 0)
          );
        }, 0);
      } else {
        totalExpense = 0;
      }

      setTotalExpense(totalExpense);
    } else {
      setTotalExpense(0);
    }
  }, [allExpenses, expenseFilter]);

  useEffect(() => {
    const accountsToPay = accounts?.filter((account) => {
      return account?.type == "Account Receivable A/R";
    });

    if (accountsToPay?.length > 0) {
      const total = accountsToPay
        ?.reduce((sum, account) => {
          return parseFloat(sum) + parseFloat(account?.balance || 0);
        }, 0)
        ?.toFixed();
      setTotalAR(total);
    } else {
      setTotalAR(0);
    }
  }, [totalRevenue, totalExpense, accounts]);

  // Function to format time as "HH:MM"
  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Get current date and time

  const handleCheckIn = async () => {
    const now = new Date();
    const attendanceData = {
      CheckInRecordtime: formatTime(now),
      attendenceDate: formatDate(now),
      userId: user?.userId,
    };
    try {
      const result = await postAttendance(attendanceData);
      notifySuccess(result?.data?.message);
      refetchAttendance();
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleCheckOut = async () => {
    try {
      const now = new Date();
      const updatedAttendance = {
        CheckOutRecordtime: formatTime(now),
        attendenceDate: formatDate(now),
        userId: user?.userId,
      };

      const result = await updateAttendance({
        attendanceId:
          todayAttendence[todayAttendence?.length - 1]?.attendanceId,
        updatedAttendance,
      }).unwrap();

      notifySuccess(result?.message);
      refetchAttendance();
    } catch (error) {
      notifyError(error.message);
    }
  };

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12 col-xxl-12">
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  {true && (
                    <div
                      className={`${
                        user?.role == "Admin" ? "col-xl-12" : "col-xl-12"
                      }`}
                    >
                      <div className="row">
                        {user?.role == "Admin" && (
                          <>
                            <div className="col-xl-4 col-sm-4">
                              <div className="card bg-primary text-white">
                                <div className="card-header border-0 flex-wrap">
                                  <div className="revenue-date">
                                    <span>Revenue</span>
                                    <h4 className="text-white">{`USD ${totalRevenue?.toFixed(
                                      2
                                    )}`}</h4>
                                  </div>
                                  <div className=" me-2">
                                    {/* //avatar-list avatar-list-stacked */}
                                    {/* <img
                                src={IMAGES.contact5}
                                className="avatar rounded-circle"
                                alt=""
                              />{" "}
                              <img
                                src={IMAGES.contact6}
                                className="avatar rounded-circle"
                                alt=""
                              />{" "}
                              <span className="avatar rounded-circle bg-white">
                                25+
                              </span> */}

                                    <Dropdown className="">
                                      <Dropdown.Toggle
                                        as="div"
                                        style={{ color: "white" }}
                                      >
                                        {revenueFilter}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="task-drop-menu">
                                        {filterOptions
                                          ?.filter((data) => {
                                            return (
                                              data?.title !== revenueFilter
                                            );
                                          })
                                          ?.map((item) => (
                                            <Dropdown.Item
                                              onClick={() =>
                                                setRevenueFilter(item?.title)
                                              }
                                            >
                                              {item?.title}
                                            </Dropdown.Item>
                                          ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="card-body pb-0 custome-tooltip d-flex align-items-center">
                                  <RevenueChart />
                                  <div>
                                    {SVGICON.WhiteArrowUp}
                                    <span className="d-block font-w600">
                                      45%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-4">
                              <div className="card bg-secondary text-white">
                                <div className="card-header border-0">
                                  <div className="revenue-date">
                                    <span className="text-black">Expenses</span>
                                    <h4 className="text-black">{`USD ${totalExpense?.toFixed(
                                      2
                                    )}`}</h4>
                                  </div>
                                  <div className=" me-2">
                                    {/* <span className="avatar rounded-circle bg-white">
                                <Link to={"#"}>{SVGICON.PlusSymbal}</Link>
                              </span> */}

                                    <Dropdown className="">
                                      <Dropdown.Toggle
                                        as="div"
                                        style={{ color: "white" }}
                                      >
                                        {expenseFilter}
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className="task-drop-menu">
                                        {filterOptions
                                          ?.filter((data) => {
                                            return (
                                              data?.title !== expenseFilter
                                            );
                                          })
                                          ?.map((item) => (
                                            <Dropdown.Item
                                              onClick={() =>
                                                setExpenseFilter(item?.title)
                                              }
                                            >
                                              {item?.title}
                                            </Dropdown.Item>
                                          ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="card-body pb-0 custome-tooltip d-flex align-items-center">
                                  <ExpensesBarChart />
                                  <div>
                                    {SVGICON.DarkArrowUp}
                                    <span className="d-block font-w600 text-black">
                                      45%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-4">
                              <div className="card bg-success text-white">
                                <div className="card-header border-0 flex-wrap">
                                  <div className="revenue-date">
                                    <span>Receivable</span>
                                    <h4 className="text-white">{`USD ${totalAR}`}</h4>
                                  </div>
                                  {/* <div className="avatar-list avatar-list-stacked me-2">
                              <img
                                src={IMAGES.contact5}
                                className="avatar rounded-circle"
                                alt=""
                              />{" "}
                              <img
                                src={IMAGES.contact6}
                                className="avatar rounded-circle"
                                alt=""
                              />{" "}
                              <span className="avatar rounded-circle bg-white">
                                25+
                              </span>
                            </div> */}
                                </div>
                                <div className="card-body pb-0 custome-tooltip d-flex align-items-center">
                                  <RevenueChart />
                                  <div>
                                    {SVGICON.WhiteArrowUp}
                                    <span className="d-block font-w600">
                                      45%
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-xl-4 col-sm-4">
                          <div className="card">
                            <div className="card-body depostit-card">
                              <div className="depostit-card-media d-flex justify-content-between style-1">
                                <div>
                                  <h6> Total Task</h6>
                                  <h3>{taskData?.totalTask}</h3>
                                </div>
                                <div className="icon-box bg-secondary">
                                  {SVGICON.Shiled}
                                </div>
                              </div>
                              <div className="progress-box mt-0">
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Complete Task</p>
                                  <p className="mb-0">{`${taskData?.completeTask}/${taskData?.totalTask}`}</p>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-secondary"
                                    style={{
                                      width: `${
                                        (taskData?.completeTask * 100) /
                                        taskData?.totalTask
                                      }%`,
                                      height: "5px",
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 col-sm-4">
                          <div className="card">
                            <div className="card-body depostit-card">
                              <div className="depostit-card-media d-flex justify-content-between style-1">
                                <div>
                                  <h6> Task Pending</h6>
                                  <h3>{taskData?.pendingTask}</h3>
                                </div>
                                <div className="icon-box bg-secondary">
                                  {SVGICON.Shiled}
                                </div>
                              </div>
                              <div className="progress-box mt-0">
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Pending Task</p>
                                  <p className="mb-0">{`${
                                    taskData?.pendingTask
                                  }/${
                                    taskData?.totalTask - taskData?.completeTask
                                  }`}</p>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-secondary"
                                    style={{
                                      width: `${
                                        (taskData?.pendingTask * 100) /
                                        taskData?.totalTask
                                      }%`,
                                      height: "5px",
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-4 col-sm-4">
                          <div className="card">
                            <div className="card-body depostit-card">
                              <div className="depostit-card-media d-flex justify-content-between style-1">
                                <div>
                                  <h6>Tasks in Progress</h6>
                                  <h3>{taskData?.inProgressTask}</h3>
                                </div>
                                <div className="icon-box bg-secondary">
                                  {SVGICON.Shiled}
                                </div>
                              </div>
                              <div className="progress-box mt-0">
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">In Progress Task</p>
                                  <p className="mb-0">{`${
                                    taskData?.inProgressTask
                                  }/${
                                    taskData?.totalTask - taskData?.completeTask
                                  }`}</p>
                                </div>
                                <div className="progress">
                                  <div
                                    className="progress-bar bg-secondary"
                                    style={{
                                      width: `${
                                        (taskData?.inProgressTask * 100) /
                                        taskData?.totalTask
                                      }%`,
                                      height: "5px",
                                      borderRadius: "4px",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-xl-6 col-sm-6">
                        <div className="card same-card">
                          <div className="card-body depostit-card p-0">
                            <div className="depostit-card-media d-flex justify-content-between pb-0">
                              <div>
                                <h6>Total Deposit</h6>
                                <h3>$1200.00</h3>
                              </div>
                              <div className="icon-box bg-primary">
                                {SVGICON.Doller}
                              </div>
                            </div>
                            <TotalDeposit />
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  )}


                  {/* <div className="col-xl-3 col-sm-6">
                    <div
                      className="card bg-success rainbow-box"
                      style={{
                        backgroundImage: "url(" + Rain + ")",
                        backgroundSize: "cover",
                        backgroundBlendMode: "luminosity",
                      }}
                    >
                      <div className="card-header border-0">
                        {SVGICON.WhiteLogo}
                      </div>
                      <div className="card-body pt-0">
                        <div className="finance">
                          <h4>Your Finances, safe and Secure</h4>
                          <p>
                            It is a long established fact that a reader will be
                            distracted by the readable content of a page when
                            looking at its layout.
                          </p>
                        </div>
                        <div className="d-flex pt-3">
                          <div className="avatar-list avatar-list-stacked">
                            <img
                              src={IMAGES.contact5}
                              className="avatar rounded-circle"
                              alt=""
                            />
                            <img
                              src={IMAGES.contact6}
                              className="avatar rounded-circle"
                              alt=""
                            />
                            <img
                              src={IMAGES.contact7}
                              className="avatar rounded-circle"
                              alt=""
                            />
                          </div>
                          <div className="ratting-data">
                            <h4>15k+</h4>
                            <span>Happy Clients</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {user?.role != "Admin" ? (
                    <div className="col-xl-4 col-sm-6">
                      <Card
                        className="text-center shadow-sm"
                        style={{ borderRadius: "10px" }}
                      >
                        <Card.Header
                          style={{
                            backgroundColor: "#f5f5f5",
                            borderBottom: "3px solid #7b69ec",
                            fontWeight: "bold",
                            textAlign: "center",
                            padding: "5px", // Add padding to ensure there's space around the text
                            margin: 0, // Ensure there's no default margin affecting alignment
                            display: "flex", // Use flexbox for alignment
                            alignItems: "center", // Vertically center
                            justifyContent: "center", // Horizontally center
                            height: "60px", // Adjust height as needed
                          }}
                        >
                          CLOCK IN
                        </Card.Header>

                        <Card.Body>
                          <h1
                            className="mb-1"
                            style={{ fontWeight: "bold", fontSize: "2rem" }}
                          >
                            {todayAttendence?.length != 0
                              ? calculateTotalHours(todayAttendence)
                              : "00:00 "}{" "}
                            hrs
                          </h1>
                          <p className="text-muted mb-5">Today's Hours</p>
                          <div className="d-flex justify-content-center">
                            {todayAttendence.length > 0 &&
                            todayAttendence[todayAttendence.length - 1]
                              ?.CheckOutRecordtime == null ? (
                              <Button
                                variant="outline-secondary"
                                onClick={handleCheckOut}
                                className="mx-2"
                              >
                                Clock Out
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                onClick={handleCheckIn}
                                className="mx-2"
                              >
                                Clock In
                              </Button>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  ) : (
                    <></>
                    // <div className="col-xl-3 col-sm-6">
                    //   <div className="card">
                    //     <div className="card-body">
                    //       <MyProgressChart />
                    //       <span className="right-sign">
                    //         {SVGICON.RightCheck}
                    //       </span>
                    //       <div className="redia-date text-center">
                    //         <h4>My Progress</h4>
                    //         <p>Lorem ipsum dolor sit amet, consectetur</p>
                    //         <Link
                    //           to={"/crm"}
                    //           className="btn btn-secondary text-black"
                    //         >
                    //           More Details
                    //         </Link>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  )}

                  {user?.role == "Admin" && (
                    <div className="col-xl-6">
                      <ProjectOverviewTab height={300} type="simplelead" />
                    </div>
                  )}

                  <div
                    className={`${
                      user?.role == "Admin" ? "col-xl-6" : "col-xl-8"
                    }`}
                  >
                    <ToDoList />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-xl-3 respo col-xxl-4 col-lg-5">
            <div className="row">
             
              <div className="col-xl-12">
                <AllProjectBlog />
              </div>
            </div>
          </div> */}

          {user?.role !== "Admin" && (
            <div className="col-xl-6 col-xxl-12">
              <ActiveProjects />
              {/* <ActiveProjects2 /> */}
            </div>
          )}

          {user?.role == "Admin" && (
            <div className="col-xl-6 col-xxl-12">
              <ProjectOverviewTab height={250} type="leadOverView" />
              {/* <ActiveProjects /> */}
              {/* <ActiveProjects2 /> */}
            </div>
          )}

          {/* <div className="col-xl-6 col-xxl-8 col-lg-7">
            <div className="card h-auto">
              <div className="card-header border-0 pb-3">
                <h4 className="heading mb-0">Running Projects</h4>
                <Link to={"#"} className="btn btn-sm btn-primary">
                  View All
                </Link>
              </div>
              <div className="card-body py-0">
                <RunningSwiper />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
const calculateTotalHours = (records) => {
  let totalMilliseconds = 0;

  records.forEach((record) => {
    if (record?.CheckOutRecordtime != null) {
      const checkIn = new Date(`1970-01-01T${record?.CheckInRecordtime}:00Z`);
      const checkOut = new Date(`1970-01-01T${record?.CheckOutRecordtime}:00Z`);
      totalMilliseconds += checkOut - checkIn;
    } else {
      totalMilliseconds = 0;
    }
  });

  const totalHours = totalMilliseconds / (1000 * 60 * 60);

  return totalHours?.toFixed(2);
};
export default Home;
