// Create.jsx
import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import CustomClearIndicator from "../../Comman/MultiSelect";
import { useGetServicesQuery } from "../../../../features/service/serviceApiSlice";

import Select from "react-select";
import { useGetRoleQuery } from "../../../../features/userManagement/userManagementApiSlice";
import { useGetSupervisersQuery } from "../../../../features/superviser/superviserApiSlice";
import { usePostEmployeeMutation } from "../../../../features/employee/employeeApiSlice";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../../../helpers/Notify";
import * as Yup from "yup";
import "react-tagsinput/react-tagsinput.css";

const Create = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const storedUser = localStorage.getItem("user");
  const [selectedRole, setSelectedRole] = useState("");
  const [firstInitialValue, setFirstInitialValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    // bankDetails: "",
    passport: "",
    passportExpireDate: "",
    visa: "",
    visaExpireDate: "",
    dateOfBirth: "",
    address: "",
    gender: "",
    services: [],

    role: {},
    profilePicture: null,
    supervisersWithServiceId: [],
  });

  const ownerRole = JSON.parse(storedUser)?.role;
  const navigate = useNavigate();
  const [profilePic, setProfilePicture] = useState(null);
  const { data: services = [] } = useGetServicesQuery({
    fetchPolicy: "network-only",
  });
  const { data: roles = [] } = useGetRoleQuery({
    fetchPolicy: "network-only",
  });

  const { data: supervisers = [] } = useGetSupervisersQuery({
    fetchPolicy: "network-only",
  });
  const [isView, setIsView] = useState(false);
  const [rendringData, setRendringData] = useState([
    "firstname",
    "lastname",
    "email",
    "password",
    "mobile",
    "dateOfBirth",
    "passport",
    "visa",
    "emiratesId",
    "passportExpireDate",
    "visaExpireDate",
    "emiratesIdExpireDate",
    "emergencyContactName",
    "emergencyContactPhoneNumber",
    "emergencyContactRelation",
    "role",
    "gender",

    "address",
  ]);

  useEffect(() => {
    // Find the index of "role" in the array
    const roleIndex = rendringData.indexOf("role");

    // Create a copy of the array
    let updatedData = [...rendringData];

    if (!selectedRole?.supervisorRequired) {
      const stringsToRemove = ["superviser"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
      // if (!updatedData.includes("services")) {
      //   updatedData.splice(roleIndex + 1, 0, "services");
      // }
    } else if (selectedRole?.supervisorRequired) {
      const stringsToRemove = ["services"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
      if (!updatedData.includes("superviser")) {
        updatedData.splice(roleIndex + 1, 0, "superviser");
      }
    } else {
      const stringsToRemove = ["superviser", "subservices", "services"];
      updatedData = updatedData.filter(
        (item) => !stringsToRemove.includes(item)
      );
    }
    setRendringData(updatedData);
  }, [selectedRole]);

  const loginSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("First Name is required")
      .max(50, "First Name must be less than 50 characters"),
    lastname: Yup.string()
      .required("Last Name is required")
      .max(50, "Last Name must be less than 50 characters"),

    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .max(40, "Email must be less than 40 characters"),
      // .test(
      //   "domain",
      //   "Email must end with @aifaservices.com",
      //   (value) => value && value.endsWith("@aifaservices.com")
      // ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(50, "Password must be less than 50 characters")
      .required("Password is required"), // Password is optional
    mobile: Yup.string()
      .min(9, "Mobile Number must be at least 9 characters")
      .max(15, "Mobile Number must be less than 15 characters")
      .matches(/^\+?\d{9,15}$/, "Invalid Mobile Number")
      .required("Mobile Number is required"),
    passport: Yup.string()
      .required("Passport Number is required")
      .max(20, "Passport number must be less than 20 characters"),

    emiratesId: Yup.string()
      .required("Emirates ID is required")
      .matches(/^\d{15}$/, "Emirates ID must be exactly 15 digits")
      .max(20, "Emirates ID Number must be less than 20 digits"),
    emiratesIdExpireDate: Yup.date().required(
      "Emirates ID Expire Date is required"
    ),
    passportExpireDate: Yup.date().required("Passport Expire Date is required"),
    emergencyContactName: Yup.string()
      .optional("Emergency Contact Name is required")
      .min(3, "Emergency Contact Name must be at least 3 characters")
      .max(50, "Emergency Contact Name must be less than 50 characters"),
    emergencyContactPhoneNumber: Yup.string()
      .min(9, "Emergency Contact Number must be at least 9 characters")
      .max(15, "Emergency Contact Number must be less than 15 characters")
      .matches(/^\+?\d{9,15}$/, "Invalid Emergency Contact Phone Number")
      .optional("Emergency Contact Number is required"),

    emergencyContactRelation: Yup.string().optional(
      "Emergency Contact Relation Name is required"
    ),
    visa: Yup.string()
      .required("Visa Number is required")
      .max(20, "Visa number must be less than 20 characters"),
    visaExpireDate: Yup.date().required("Visa Expire Date is required"),
    dateOfBirth: Yup.date().optional(),
    address: Yup.string()
      .optional()
      .max(255, "Address must be less than 255 characters"), // Address is optional
    gender: Yup.string().required("gender is required"), // Gender is optional
    // services: Yup.array().notRequired(), // Services are optional
    role: Yup.object().required("Role is required"),

    profilePicture: Yup.mixed()
      .notRequired() // Profile picture is optional
      .test(
        "fileSize",
        "File size is too large (max 5MB)",
        (value) => !value || (value && value.size <= 5 * 1024 * 1024)
      ),
  });

  firstInitialValue.supervisersWithServiceId = supervisers?.users;

  useEffect(() => {
    const currentPath = window.location.pathname;
    const containsView = currentPath.includes("/view/");

    if (containsView) {
      setIsView(true);
    }
  }, []);

  const [postEmployee, { isLoading }] = usePostEmployeeMutation();
  const capitalizeFirstLetter = (str) => {
    if (str === "firstname") {
      return "First Name";
    } else if (str === "lastname") {
      return "Last Name";
    } else if (str === "lastname") {
      return "Last Name";
    } else if (str === "bankDetails") {
      return "Bank Details";
    } else if (str === "dateOfBirth") {
      return "Date Of Birth";
    } else if (str === "superviser") {
      return "Supervisor";
    } else if (str === "passportExpireDate") {
      return "Passport Expire Date";
    } else if (str === "visaExpireDate") {
      return "Visa Expire Date";
    } else if (str === "emiratesId") {
      return "Emirates ID Number";
    } else if (str === "visa") {
      return "Visa Number";
    } else if (str === "emiratesIdExpireDate") {
      return "Emirates ID Expire Date";
    } else if (str === "passport") {
      return "Passport Number";
    } else if (str === "emergencyContactName") {
      return "Emergency Contact Name";
    } else if (str === "emergencyContactPhoneNumber") {
      return "Emergency Contact  Number";
    } else if (str === "emergencyContactRelation") {
      return "Emergency Contact Relation";
    } else {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  };
  const handleSubmitted = async (values) => {
    const copyData = values;

    values.role = values?.role?.label;
    values.roleId = selectedRole?.roleId;
    values.firstName = values?.firstname;
    values.lastName = values?.lastname;
    if (values?.role === "Employee") {
      values.supervisorId = values?.superviser?.value;
    } else {
      delete values?.supervisorId;
    }

    try {
      const result = await postEmployee(values).unwrap();

      if (result?.message === "User created successfully" || result?.userId) {
        if (ownerRole == "Supervisor") {
          notifySuccess(result?.data?.message);

          navigate(`/${ownerRole?.toLowerCase()}/employee`);
        } else {
          navigate(
            `/${ownerRole?.toLowerCase()}/employee/official-information/add/${
              result?.userId ? result?.userId : result?.data?.userId
            }`
          );
        }
      } else if (result?.error?.status === 400) {
        const errorMessage = result?.error?.data?.message || "Unknown error";
        values.services = copyData?.services;
        values.role = copyData?.role;
        values.firstname = copyData?.firstname;
        values.lastname = copyData?.lastname;
        notifyError(errorMessage);
      } else {
        values.services = copyData?.services;
        values.role = copyData?.role;
        values.firstname = copyData?.firstname;
        values.lastname = copyData?.lastname;
        // Handle other status codes if needed
        notifyError(result?.data?.message || "Unexpected error occurred");
      }
    } catch (error) {
      values.services = copyData?.services;
      values.role = copyData?.role;
      values.firstname = copyData?.firstname;
      values.lastname = copyData?.lastname;
      console.error(error);
      notifyError(
        error?.data?.message || "Failed to create employee. Please try again."
      ); // Default error message
      // Handle error, access error.data if needed
    }
  };

  const handleSuperviserChange = (value) => {
    const roleIndex = rendringData.indexOf("role");

    // Create a copy of the array
    const updatedData = [...rendringData];

    const supperviserData = supervisers?.users?.find(
      (user) => user?.userId === value?.value
    );

    const userServicesIds = supperviserData?.services;

    setRendringData(updatedData);
  };
  // Define your gender options
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Employee</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik
                    initialValues={firstInitialValue}
                    enableReinitialize={true}
                    validationSchema={loginSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      handleSubmitted(values);
                      setSubmitting(false);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          {rendringData?.map((fieldName, index) => (
                            <div
                              key={index}
                              className={`form-group mb-3 col-xl-4`}
                            >
                              {true && (
                                <label className="text-label">
                                  {capitalizeFirstLetter(fieldName)}
                                </label>
                              )}

                              {fieldName === "role" && (
                                <>
                                  <Select
                                    name={fieldName}
                                    onChange={(value) => {
                                      setFieldValue("role", value);
                                      setSelectedRole(value?.role);
                                    }}
                                    onBlur={handleBlur}
                                    disabled={isView}
                                    options={roles
                                      ?.filter(
                                        (role) => role?.roleName != "Admin"
                                      )
                                      ?.map((role) => ({
                                        value: role?.roleId,
                                        label: role.roleName,
                                        role: role,
                                      }))}
                                    style={{
                                      lineHeight: "40px",
                                      color: "#7e7e7e",
                                      paddingLeft: " 15px",
                                    }}
                                    value={values?.role}
                                    required
                                  />
                                  <div
                                    id={`val-${fieldName}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched[fieldName] && errors[fieldName]
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <ErrorMessage name={fieldName} />
                                  </div>
                                </>
                              )}

                              {fieldName === "gender" && (
                                <Select
                                  options={genderOptions}
                                  value={genderOptions.find(
                                    (option) => option.value === values.gender
                                  )}
                                  onChange={(selectedOption) =>
                                    setFieldValue(
                                      "gender",
                                      selectedOption.value
                                    )
                                  }
                                  placeholder="Select gender"
                                />
                              )}

                              {fieldName === "address" && (
                                <div className="input-group">
                                  <textarea
                                    className="form-control"
                                    id={`val-${fieldName}`}
                                    placeholder={`${capitalizeFirstLetter(
                                      fieldName
                                    )}`}
                                    name={fieldName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values[fieldName]}
                                    disabled={isView}
                                  />
                                  <div
                                    id={`val-${fieldName}-error`}
                                    className="invalid-feedback animated fadeInUp"
                                    style={{
                                      display:
                                        touched[fieldName] && errors[fieldName]
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <ErrorMessage name={fieldName} />
                                  </div>
                                </div>
                              )}
                              {fieldName === "superviser" && (
                                <Select
                                  name={fieldName}
                                  onChange={(value) => {
                                    setFieldValue("superviser", value);
                                    handleSuperviserChange(value);
                                  }}
                                  onBlur={handleBlur}
                                  disabled={isView}
                                  options={supervisers?.users?.map((user) => ({
                                    value: user.userId,
                                    label: `${user.firstName} ${user.lastName}`,
                                  }))}
                                  style={{
                                    lineHeight: "40px",
                                    color: "#7e7e7e",
                                    paddingLeft: " 15px",
                                  }}
                                />
                              )}
                              {fieldName !== "role" &&
                                fieldName !== "services" &&
                                fieldName !== "subservices" &&
                                fieldName !== "gender" &&
                                fieldName !== "address" &&
                                fieldName !== "superviser" && (
                                  <div className="input-group">
                                    <input
                                      type={
                                        fieldName === "password" &&
                                        !showPassword
                                          ? "password"
                                          : fieldName === "mobile"
                                          ? "tel"
                                          : fieldName === "profilePicture"
                                          ? "file"
                                          : fieldName === "dateOfBirth"
                                          ? "date"
                                          : fieldName === "visaExpireDate"
                                          ? "date"
                                          : fieldName === "passportExpireDate"
                                          ? "date"
                                          : fieldName === "emiratesIdExpireDate"
                                          ? "date"
                                          : "text"
                                      }
                                      className="form-control"
                                      id={`val-${fieldName}`}
                                      placeholder={`${capitalizeFirstLetter(
                                        fieldName
                                      )}`}
                                      name={fieldName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values[fieldName]}
                                      disabled={isView}
                                      max={
                                        fieldName == "dateOfBirth"
                                          ? new Date()
                                              ?.toISOString()
                                              ?.slice(0, 10)
                                          : false
                                      }
                                    />
                                    {fieldName === "password" && (
                                      <div
                                        className="input-group-append"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleTogglePassword}
                                      >
                                        <span className="form-control input-group-text">
                                          {showPassword ? (
                                            <i className="fas fa-eye-slash"></i>
                                          ) : (
                                            <i className="fas fa-eye"></i>
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    <div
                                      id={`val-${fieldName}-error`}
                                      className="invalid-feedback animated fadeInUp"
                                      style={{
                                        display:
                                          touched[fieldName] &&
                                          errors[fieldName]
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <ErrorMessage name={fieldName} />
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>

                        <div className="text-right">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            disabled={isSubmitting}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Create;
